.form {
  background-color: var(--lien-color);
}

.form .errorText {
  color: var(--bonus-color);
  font-size: 16px;
}

.form .form-group {
  width: 100%;
}

.form .phone-link {
  font-size: 1.5rem;
  text-decoration: none;
  color: inherit;
}

.form .icon-contact {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
