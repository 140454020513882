.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
}

.modal {
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;
  border-radius: 5px;
  height: auto;
  width: 80%;
  padding: 1rem;
  display: block;
}
@media only screen and (max-width: 960px) {
  .modal {
    width: 100vw;
  }
}

.modal.rdv{
background: var(--lien-color);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  border: none;
  background: transparent;
}
.modal-close-button.right {  
    margin: 0 0 0 95%;
}

.modal-toggle {
  border: none;
  background: none;
  font-size: 0.9rem;
}
.modal-toggle:hover {
  border-bottom: 2px solid;
  transform: scale(1.1);
}
