:root {
  --titre-color: #2a2b2e;
  --text-color: #2b363b;
  --bonus-color: #b61624;
  --fond1-color: #fef7eb;
  --fond2-color: #fdedee;
  --lien-color: #f9a620;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  font-size: 1.1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}
h1,
h2,
h3 {
  font-family: "Anton", sans-serif !important;
  color: var(--titre-color);
}

.row {
  width: 100%;
}

.slide-top:hover {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    backwards;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) backwards;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
