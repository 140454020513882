#footer {
  min-height: "10em";
  background-color: var(--text-color);
}

.nav-link {
  color: white;
}
.nav-link:hover {
  color: var(--lien-color);
}
