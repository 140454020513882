.btn {
  display: flex;
  justify-content: center;
  border: 0;
  margin: 10px 0;
  border-radius: var(--btn-corner, 3px);
  background: var(--btn-bg, #194769);
  background-image: linear-gradient(var(--button-bg-img));
  color: var(--btn-color, #fff);
  padding: var(--btn-padding, 10px 20px);
  text-transform: uppercase;
  font-weight: 700;
}
.btn:hover {
  border: 0 !important;
  margin: 10px 0;
  border-radius: var(--btn-corner, 3px);
  background: var(--btn-bg, #194769);
  background-image: linear-gradient(var(--button-bg-img-hover)) !important;
  color: var(--btn-color, #fff);
  padding: var(--btn-padding, 10px 20px);
  text-transform: uppercase;
  font-weight: 700;
}
.btn-perso-primary {
  background-repeat: no-repeat;
  --button-bg-img: #da1b2b, #b61624 50%, #91121d;
  --button-bg-img-hover: #da1b2b, #e63746 50%, #eb5c68;
}

.btn-perso-success {
  --btn-bg: #0e9577;
}

.btn-danger {
  --btn-bg: #a40a3c;
}

.btn-perso-lg {
  --btn-corner: 25px;
  --btn-padding: 15px;
}
