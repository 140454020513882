#menu {
  padding: 15px;
  transition: all 0.8s;
  font-size: 15px;
  background-color: var(--lien-color);
}
#menu .nav {
  display: flex;
  flex-direction: column;
  align-content: space-around;
}
#menu .nav-link {
  color: var(--text-color);
  margin-right: 5px;
}
#menu .nav-link:hover {
  border-bottom: 4px solid;
  border-color: var(--text-color);
}
#menu .active {
  border-bottom: 4px solid;
  border-color: var(--text-color);
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  #menu {
    font-size: 20px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #menu {
    font-size: 18px;
  }
  #menu .nav {
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  #menu {
    font-size: 22px;
  }
}
