.banierre {
  background: url("../../asset/images/HayonDolhandia.webp");
  width: 100%;
  max-height: 600px;
  position: relative;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: 80% 60%;
  background-repeat: no-repeat;
  margin-top: 80px;
}
.banierre::after {
  opacity: 0.5;
}
.banierre .intro-text {
  color: var(--text-color);
  padding-top: 120px;
  padding-bottom: 240px;
  font-weight: 800;
  text-align: center;
  font-size: 40px;
}

.banierre .intro-text h1 {
  font-size: 80px;
  font-weight: 600;
  letter-spacing: -1px;
  margin-top: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.jumbotron-contact {
  background: linear-gradient(to right, #585858 0%, var(--titre-color) 100%);

  position: relative;
  clear: both;
  top: -80px;
}
.jumbotron-contact h2,
.jumbotron-contact h3 {
  color: white !important;
}
.overlay {
  background: linear-gradient(
    to bottom,
    rgba(249, 166, 32, 0.6) 50%,
    rgba(160, 160, 160, 0.6) 100%
  );
}
@media screen and (max-width: 640px) {
  .banierre .intro-text {
    padding-top: 60px;
    padding-bottom: 120px;
    font-weight: 800;
    text-align: center;
    font-size: 30px;
  }
  .banierre .intro-text h1 {
    font-size: 50px;
    font-weight: 700;
    letter-spacing: -1px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .jumbotron-contact h2,
  .jumbotron-contact h3 {
    font-size: 25px;
  }
}
