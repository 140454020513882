section {
  margin-top: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 5px;
  padding-right: 5px;
}
section .titre {
  position: relative;
  text-transform: uppercase;
  margin-bottom: 60px;
  font-size: 40px;
  font-weight: 600;
  padding-bottom: 20px;
}
section .titre::after {
  position: absolute;
  content: "";
  background: linear-gradient(
    to right,
    var(--fond2-color) 0%,
    var(--lien-color) 75%
  );
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
